import React from "react";
import ReactDOM from "react-dom/client";
import JSON5 from "json5";

const instancesMap = new Map();

export const getComponentRootEls = id =>
  document.querySelectorAll(`[data-id="${id}"]`);

// Elements kept for SEO or for when there's no JS.
export const cleanSEOElements = id =>
  document.querySelectorAll(`[data-hide-id="${id}"]`).forEach(el => {
    el.classList.add("hidden");
  });

export const getProps = el => {
  const rawProps = el.dataset.props;
  return rawProps ? JSON5.parse(rawProps) : {};
};

export const renderStructuredElements = (id, Component, stores) => {
  getComponentRootEls(id).forEach(el => {
    const props = getProps(el);
    const root = ReactDOM.createRoot(el);

    instancesMap.set(id, root);

    root.render(
      <React.StrictMode>
        <Component {...props} {...stores}>
          {el.innerHTML && (
            <div dangerouslySetInnerHTML={{__html: el.innerHTML}}></div>
          )}
        </Component>
      </React.StrictMode>,
    );
    // Avoids page jumps.
    setTimeout(() => {
      el.removeAttribute("react-cloak");
    }, 200);
  });

  cleanSEOElements(id);
};

export const reRenderStructuredElements = (id, Component, stores) => {
  getComponentRootEls(id).forEach(el => {
    const props = getProps(el);
    const root = instancesMap.get(id);
    root.render(
      <React.StrictMode>
        <Component {...props} {...stores}>
          {el.innerHTML && (
            <div dangerouslySetInnerHTML={{__html: el.innerHTML}}></div>
          )}
        </Component>
      </React.StrictMode>,
    );
    // Avoids page jumps.
    setTimeout(() => {
      el.removeAttribute("react-cloak");
    }, 200);
  });

  cleanSEOElements(id);
};
